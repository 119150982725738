<template>
  <div class="sort">
    <select @change="changeOption()" v-model="option">
      <option value="1">Option 1</option>
      <option value="2">Option 2</option>
    </select>
    <template v-if="this.$route.query.type === 'show'">
      <select @change="changeSeason($event)">
        <option
          v-for="season in item.number_of_seasons"
          :key="season"
          :value="season"
          :selected="season == this.$route.query.season">
          Season {{ season }}
        </option>
      </select>
      <select @change="changeEpisode($event)">
        <option
          v-for="episode in episodes"
          :key="episode"
          :value="episode"
          :selected="episode == this.$route.query.episode">
          Episode {{ episode }}
        </option>
      </select>
    </template>
  </div>
  <iframe
    :src="source"
    height="100%"
    width="100%"
    frameborder="none"
    allowfullscreen="true"
    webkitallowfullscreen="true"
    mozallowfullscreen="true">
  </iframe>
</template>

<script>
  import api from '@/api'

  export default {
    data: () => ({
      episodes: '',
      item: {},
      option: '1',
      source: null
    }),
    async activated() {
      // Handle option
      this.option = this.$route.query.option || 1
      // Handle movie route
      if (this.$route.query.type === 'movie') { this.loadMovie() }
      // Handle show route
      if (this.$route.query.type === 'show') {
        // Request show details
        await api.tmdb.reqItem('film', 'tv', this.$route.query.id).then(res => this.item = res.data)
        // Set episode count
        this.episodes = this.item.seasons.find(season => season.season_number == this.$route.query.season).episode_count
        // Load episode source
        this.loadShow({ season: this.$route.query.season , episode: this.$route.query.episode })
      }
    },
    methods: {
      changeSeason(e) {
        // Update route with season and episode number
        this.$router.push({ name: 'Watch', query: { type: 'show', id: this.item.id, tag: this.item.external_ids.imdb_id, season: e.target.value, episode: 1, option: this.option }})
        // Set episode count
        this.episodes = this.item.seasons.find(season => season.season_number == e.target.value).episode_count
        // Load show
        this.loadShow({ season: e.target.value, episode: 1 })
      },
      changeEpisode(e) {
        // Update route with new episode number
        this.$router.push({ name: 'Watch', query: { type: 'show', id: this.item.id, tag: this.item.external_ids.imdb_id, season: this.$route.query.season, episode: e.target.value, option: this.option }})
        // Load show
        this.loadShow({ season: this.$route.query.season, episode: e.target.value })
      },
      changeOption() {
        // Load movie on movie route
        if (this.$route.query.type === 'movie') {
          // Update route with option
          this.$router.push({ name: 'Watch', query: { type: 'movie', id: this.$route.query.id, tag: this.$route.query.tag, option: this.option }})
          // Load movie
          this.loadMovie()
        }
        // Load show on show route
        else {
          // Update route with option
          this.$router.push({ name: 'Watch', query: { type: 'show', id: this.item.id, tag: this.item.external_ids.imdb_id, season: this.$route.query.season, episode: this.$route.query.episode, option: this.option }})
          // Load show
          this.loadShow({ season: this.$route.query.season , episode: this.$route.query.episode })
        }
      },
      loadMovie() {
        switch (this.option) {
          case '1':
            this.source = `https://www.2embed.to/embed/tmdb/movie?id=${this.$route.query.id}`
            break;
          case '2':
            this.source = `https://v2.vidsrc.me/embed/${this.$route.query.tag}`
            break;
          default:
            this.source = `https://www.2embed.to/embed/tmdb/movie?id=${this.$route.query.id}`
        }
      },
      loadShow({ season, episode }) {
        switch (this.option) {
          case '1':
            this.source = `https://www.2embed.to/embed/tmdb/tv?id=${this.$route.query.id}&s=${season}&e=${episode}`
            break;
          case '2':
            this.source = `https://v2.vidsrc.me/embed/${this.$route.query.tag}/${season}-${episode}`
            break;
          default:
            this.source = `https://www.2embed.to/embed/tmdb/tv?id=${this.$route.query.id}&s=${season}&e=${episode}`
        }
      }
    },
    deactivated() {
      // Reset item
      this.item = {}
      // Reset option
      this.option = '1'
      // Reset source
      this.source = null
    }
  }
</script>
